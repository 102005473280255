import React from 'react';

function Logins() {


    const handleLoginClick = () => {
        window.location.href = 'http://portal.universitybureau.com/login';
    };
    

  return (
    <div>
        <div id="page" className="site site_wrapper">
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-63fb9c7 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="63fb9c7" 
                style={{marginTop:'170px'}}
                data-element_type="section"
                >
                <div className="elementor-container elementor-column-gap-default">
                    <div
                    className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-aa60b72"
                    data-id="aa60b72"
                    data-element_type="column"
                    >
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                        className="elementor-element elementor-element-9b5574a elementor-widget elementor-widget-go-s-heading"
                        data-id="9b5574a"
                        data-element_type="widget"
                        data-widget_type="go-s-heading.default"
                        >
                        <div className="elementor-widget-container text-center">
                            <div className="prthalign">
                            <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                                Login As
                            </h1>
                            </div>
                        </div>
                        </div>
                        <div
                        className="elementor-element elementor-element-cbc3c3f elementor-widget elementor-widget-go-course-item"
                        data-id="cbc3c3f"
                        data-element_type="widget"
                        data-widget_type="go-course-item.default"
                        >
                        <div className="elementor-widget-container">
                            <div className="row txaa-slide-down-1">
                                <div className="col-lg-6 col-md-6 txaa-slide-down-1-item">
                                    <a onClick={handleLoginClick}>
                                    <div className="kd-course-2-card">
                                    <div className="main-img fix img-cover">
                                    <iframe style={{width:'100%',height:'100%'}} src="https://lottie.host/embed/bd783a9a-a5bd-4e07-9b8c-6eddf4b586b6/TEFTZxcjkI.json"></iframe>
                                    </div>
                                    <div className="card-content">
                                        <h4 className="title kd-heading-1 kd-font-900 text-center">
                                            Student
                                        </h4>
                                    </div>
                                    </div>
                                    </a>    
                                </div>
                                <div className="col-lg-6 col-md-6 txaa-slide-down-1-item">
                                    <a onClick={handleLoginClick} >
                                    <div className="kd-course-2-card">
                                    <div className="main-img fix img-cover">
                                    <iframe style={{width:'100%',height:'100%',scale:'1.5'}} src="https://lottie.host/embed/0c0cd566-8812-4933-9078-9a244f41bcf6/Er43pgjpyo.json"></iframe>
                                    </div>
                                    <div className="card-content">
                                        <h4 className="title kd-heading-1 kd-font-900 text-center">

                                            Recruiter
                                        </h4>
                                    </div>
                                    </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
  );
}


export default Logins;
